<template>
  <div class="content">
      <a-card :loading="loading" title="地址配置">
        <url-config></url-config>
      </a-card>
      <a-card :loading="loading" title="数据申请" style="margin-top: 20px;">
        <application/>
      </a-card>
      <a-card :loading="loading" title="申请列表/申请历史" style="margin-top: 20px;">
        <application-list/>
      </a-card>
  </div>
</template>
<script>
import { queryUserModelPermissionApi } from '@/api/home'
import application from './component/application'
import applicationList from './component/applicationList'
import urlConfig from './component/urlConfig'
export default {
  components: {
    application,
    applicationList,
    urlConfig,
  },
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.queryUserModelPermission()
  },
  methods: {
    queryUserModelPermission() {
      this.loading = true
      queryUserModelPermissionApi({}).then(res => {
          console.log(res)
          this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 15px;
  .ant-card-head{
    background: #fbfbfb;
  }
}
</style>
