<template>
    <div>
        <a-list
                :data-source="listData"
                :loading="loading"
                class="demo-loadmore-list"
                item-layout="horizontal"
        >
            <div
                    v-if="showLoadingMore"
                    slot="loadMore"
                    :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
            >
                <a-spin v-if="loadingMore"/>
            </div>
            <a-list-item slot="renderItem" slot-scope="item, index">
                <div>{{ item.modelName }}</div>
                <div>{{ item.permissionCode }}</div>
                <div>{{ item.level }}</div>
                <div style="margin-left: 100px;">
                    <a-badge :status="status_config[item.auditStr]"/>
                    {{ item.auditStr }}
                </div>
            </a-list-item>
        </a-list>
    </div>

</template>
<script>
import {queryUserModelPermissionApi} from '@/api/dataServiceHome'

const status_config = {
    '审核中': 'processing',
    '申请通过': 'success',
    '申请驳回': 'error',
    '未知状态': 'default',
    '等待审核': 'warning'
}
export default {
    data() {
        return {
            status_config,
            loading: false,
            loadingMore: false,
            showLoadingMore: true,
            listData: [],

        };
    },
    mounted() {
        this.queryUserModelPermission()
    },
    methods: {
        queryUserModelPermission() {
            queryUserModelPermissionApi({}).then(res => {
                this.listData = res.result.content

            })
        },
        handleChange() {},
    },
};
</script>
<style>
.demo-loadmore-list {
    min-height: 350px;
}
</style>