import service from './network';

export function queryUserModelPermissionApi(data) {
    return service.request({
        url: '/user/model/permission/query',
        method: 'post',
        data
    })
}

export function applyUserModelPermissionApi(data) {
    return service.request({
        url: '/user/model/permission/apply',
        method: 'post',
        data
    })
}

export function updateUserSubscribeApi(data) {
    return service.request({
        url: '/subscribe/user/model/update',
        method: 'put',
        data
    })
}

export function queryUserSubscribeApi(data) {
    return service.request({
        url: '/subscribe/user/model/query/info',
        method: 'get',
        params: data
    })
}

export function queryUserPhoneApi(data) {
    return service.request({
        url: '/user/phone',
        method: 'get',
        params: data
    })
}

export function queryUserPhoneNotifyApi(data) {
    return service.request({
        url: '/user/phone/notify',
        method: 'put',
        data
    })
}

export function startUserActivateTestApi(data) {
    return service.request({
        url: '/subscribe/activate/test',
        method: 'put',
        data
    })
}
export function startUserActivateImmediateApi(data) {
    return service.request({
        url: '/subscribe/activate/immediate',
        method: 'put',
        data
    })
}
export function startUserActivateHistoricalApi(data) {
    return service.request({
        url: '/subscribe/activate/historical',
        method: 'put',
        data
    })
}