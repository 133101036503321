<template>
    <div id="components-form-demo-advanced-search">
        <a-form :form="form" class="ant-advanced-search-form" @submit="handleSearch">
            <a-row :gutter="24">
                <a-col>
                    <a-form-item label="选择区域">
                        <a-select style="width: 120px" @change="handleChange">
                            <a-select-option v-for="(item) in regions" :key="item.regionCode" :value="item.regionCode">
                                {{ item.regionName }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-popconfirm title="确定提交表单？" @confirm="submitApplication">
                            <a-icon slot="icon" style="color: red" type="question-circle-o"/>
                            <a-button html-type="submit" type="primary"> 提交申请</a-button>
                        </a-popconfirm>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </div>
</template>
<script>
import {applyUserModelPermissionApi} from '@/api/dataServiceHome'

export default {
    data() {
        return {
            expand: false,
            form: this.$form.createForm(this, {name: 'advanced_search'}),
            regions: [],
            currentCode: '',
            currentName: ''
        };
    },
    computed: {
        count() {
            return this.expand ? 11 : 7;
        },
    },
    mounted() {
        this.$getRegionNodeChild().then(res => {
            this.regions = res
        })
    },
    methods: {
        submitApplication() {
            applyUserModelPermissionApi({
                modelCode: this.currentCode,
                level: 2,
                modelName: this.currentName
            }).then(res => {
                this.$message.success('提交成功')
            })
        },
        handleChange(e) {
            this.currentCode = e
            this.regions.forEach(item => {
                if (e === item.regionCode) {
                    this.currentName = item.regionName
                }
            })

        }
    },
};
</script>
<style>
.ant-advanced-search-form {
    padding: 24px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
    display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}

#components-form-demo-advanced-search .ant-form {
    max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}
</style>