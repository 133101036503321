<template>
    <div>
        <a-form layout="horizontal">
            <a-form-item label="测试数据URL：">
                <a-input v-model="params.testUrl" placeholder="填写测试数据URL" style="width:600px;"></a-input>
                <a-button type="link" @click="openModal('test')"> 开始推送</a-button>
                <span v-if="params.testUrl">备注:在配置保存URL并完成数据申请后点击“开始推送”获取数据</span>
            </a-form-item>
            <a-form-item label="实时数据URL：">
                <a-input v-model="params.immediateUrl" placeholder="填写实时数据URL" style="width:600px;"></a-input>
                <a-button type="link" @click="openModal('immediate')"> 开始推送</a-button>
                <span v-if="params.immediateUrl">备注:在配置保存URL并完成数据申请后点击“开始推送”获取数据</span>
            </a-form-item>
            <a-form-item label="历史数据URL：">
                <a-input v-model="params.historicalUrl" placeholder="填写历史数据URL" style="width:600px;"></a-input>
                <a-button type="link" @click="openModal('history')"> 开始推送</a-button>
                <span v-if="params.historicalUrl">备注:在配置保存URL并完成数据申请后点击“开始推送”获取数据</span>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" @click="updateUserSubscribe">保存</a-button>
            </a-form-item>
        </a-form>
        <a-modal v-model="visible" :title="title" @ok="handleOk">
            <span v-if="currentType == 'history'">选择数据时间：</span>
            <a-range-picker v-if="currentType == 'history'" style="margin-bottom: 20px;" @change="onChange"/>
            <a-input v-model="phoneNumber" disabled size="large"/>
            <a-input-search v-model="verification" placeholder="请输入验证码" size="large" style="margin-top: 20px;"
                            @search="queryUserPhoneNotify">
                <a-button slot="enterButton" :disabled="btnDisabled">
                    <span v-if="hasGetCode">{{ time }}s</span>获取验证码
                </a-button>
            </a-input-search>
            <a-button block style="margin-top: 20px;" type="primary" @click="startPushData"> 开始推送</a-button>
        </a-modal>
    </div>
</template>

<script>
let timer;
import {
    queryUserPhoneApi,
    queryUserPhoneNotifyApi,
    queryUserSubscribeApi,
    startUserActivateHistoricalApi,
    startUserActivateImmediateApi,
    startUserActivateTestApi,
    updateUserSubscribeApi
} from '@/api/dataServiceHome'

export default {
    data() {
        return {
            hasGetCode: false,
            time: 10,
            btnDisabled: false,
            params: {
                testUrl: '',
                immediateUrl: '',
                historicalUrl: ''
            },
            visible: false,
            title: '',
            phoneNumber: '',
            verification: '',
            currentType: '',
            startTime: '',
            endTime: ''
        }
    },
    mounted() {
        this.queryUserSubscribe()
    },
    methods: {
        startPushData() {
            if (this.currentType == 'test') {
                startUserActivateTestApi({verification: this.verification}).then(res => {
                    this.$message.success('操作成功，推送已开始')
                    this.visible = false
                }).catch(() => {
                    this.$message.error('推送异常，请检查信息时候填写正确')
                })
            } else if (this.currentType == 'immediate') {
                startUserActivateImmediateApi({verification: this.verification}).then(res => {
                    this.$message.success('操作成功，推送已开始')
                    this.visible = false
                }).catch(() => {
                    this.$message.error('推送异常，请检查信息时候填写正确')
                })
            } else {
                startUserActivateHistoricalApi({
                    verification: this.verification,
                    startTime: this.startTime,
                    endTime: this.endTime
                }).then(res => {
                    this.$message.success('操作成功，推送已开始')
                    this.visible = false

                }).catch(() => {
                    this.$message.error('推送异常，请检查信息时候填写正确')
                })
            }
        },
        queryUserPhoneNotify() {
            queryUserPhoneNotifyApi().then(res => {
                this.$message.success('验证码发送成功')
                this.btnDisabled = true
                this.hasGetCode = true
                timer = setInterval(() => {
                    this.time--;
                    if (this.time === 0) {
                        this.btnDisabled = false
                        clearInterval(timer)
                        this.hasGetCode = false
                        this.time = 10
                    }

                }, 1000)
            }).catch(() => {
                this.$message.error('获取验证码失败')
            })
        },
        handleOk() {
            this.visible = false
        },
        openModal(e) {
            this.visible = true
            this.currentType = e
            this.title = (e == 'test' ? '测试数据推送' : (e == 'immediate' ? '实时数据推送' : '历史数据推送'))
            queryUserPhoneApi().then(res => {
                this.phoneNumber = res.result
            }).catch(() => {
                this.$message.error('获取手机号码失败')
            })
        },
        onSearch() {},
        queryUserSubscribe() {
            queryUserSubscribeApi().then(res => {
                this.params = {...res.result}
                console.log(this.params)
            })
        },
        updateUserSubscribe() {
            updateUserSubscribeApi({...this.params}).then(res => {
                this.$message.success('保存成功')
                this.queryUserSubscribe()
            })
        },
        onChange(e) {
            this.startTime = e[0]
            this.endTime = e[1]
        }
    }
}
</script>